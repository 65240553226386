import {
  SCHEDULE_OPERATIONAL_REQUEST,
  SCHEDULE_OPERATIONAL_SUCCESS,
  SCHEDULE_OPERATIONAL_ERROR,
  SCHEDULE_DETAIL_OPERATIONAL_REQUEST,
  SCHEDULE_DETAIL_OPERATIONAL_SUCCESS,
  SCHEDULE_DETAIL_OPERATIONAL_ERROR,
  SCHEDULE_SWITCH_REQUEST,
  SCHEDULE_SWITCH_SUCCESS,
  SCHEDULE_SWITCH_ERROR,
} from "./constant";

export function reducer(state, action) {
  switch (action.type) {
    case SCHEDULE_OPERATIONAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SCHEDULE_OPERATIONAL_SUCCESS:
      return {
        ...state,
        loading: false,
        dataOperations: action.payload,
      };
    case SCHEDULE_OPERATIONAL_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SCHEDULE_DETAIL_OPERATIONAL_REQUEST:
      return {
        ...state,
        loadingDetail: true,
      };
    case SCHEDULE_DETAIL_OPERATIONAL_SUCCESS:
      return {
        ...state,
        loadingDetail: false,
        detailOperations: action.payload,
      };
    case SCHEDULE_DETAIL_OPERATIONAL_ERROR:
      return {
        ...state,
        loadingDetail: false,
      };
    case SCHEDULE_SWITCH_REQUEST:
      return {
        ...state,
        loadingSwitch: true,
      };
    case SCHEDULE_SWITCH_SUCCESS:
      return {
        ...state,
        loadingSwitch: false,
        isAvailable: action.payload,
      };
    case SCHEDULE_SWITCH_ERROR:
      return {
        ...state,
        loadingSwitch: false,
      };
    default:
      return state;
  }
}
