import { toast } from "react-toastify";

export function toastErrorMessage(msg) {
  toast.error(msg, {
    position: "top-right",
    autoClose: 3000,
    theme: "colored",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function toastSuccessMessage(msg) {
  toast.success(msg, {
    position: "top-right",
    autoClose: 3000,
    theme: "colored",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}