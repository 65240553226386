import React from 'react'

const Footer = () => {
  return (
    <div className='bg-grey py-4 text-center'>
      <p className='mb-0 text-medium'>PT Riliv Psikologi Indonesia</p>
    </div>
  )
}

export default Footer