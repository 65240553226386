import moment from "moment";
import React, { useEffect } from "react";
import AddSchedule from "../../components/AddSchedule";
import Calendar from "../../components/Calendar";
import Footer from "../../components/Footer";
import SwitchSchedule from "../../components/SwitchSchedule";
import { useOperationsContext } from "../../context/schedule-operational/operationsContext";
moment.locale("id");

const Main = () => {
  const [, , getData] = useOperationsContext();

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <AddSchedule
        isShowSwitch
        subtitle="Atur jadwal operasional konseling yang akan ditampilkan pada klien
              Anda"
      />
      <SwitchSchedule />
      <Calendar />
      <Footer />
    </>
  );
};

export default Main;
