import React, { useEffect } from "react";
import Image from "react-bootstrap/Image";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useCallback } from "react";
import callApi from "../../utils/fetch";
import { useLocalStorage } from "../../hook/storage";
import { NavLink, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

function LoginImage({ image, name }) {
  return (
    <div className="d-flex align-items-center">
      <span className="text text-dark me-3">Hi, {name}</span>
      <Image roundedCircle src={image} alt="user" width={40} height={40} />
    </div>
  );
}

const NavbarComponent = () => {
  const navigate = useNavigate();
  const path = useLocation();
  const [token] = useLocalStorage("auth");
  const [data, setData] = useState({});

  const getProfile = useCallback(async () => {
    try {
      const res = await callApi({
        url: "/auth/psycholog/profile",
        method: "GET",
        token: token,
      });
      setData(res);
    } catch (error) {}
  }, [token]);

  const handleCheckout = () => {
    localStorage.removeItem("auth");
    window.location.replace("/");
  };

  useEffect(() => {
    if (token === undefined) {
      navigate("/");
    } else {
      getProfile();
    }
  }, [token, navigate, getProfile]);

  if (token) {
    return (
      <Navbar collapseOnSelect expand="lg" bg="white">
        <Container className="py-2 align-items-center">
          <Navbar.Brand href="/main">
            <img src="/riliv-logo.png" width={74} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            data-bs-toggle="collapse"
            data-bs-target="#responsive-navbar-nav"
          />
          <Navbar.Collapse className="mt-3 mt-md-0" id="responsive-navbar-nav">
            <Nav className="me-auto">
              <NavLink
                eventKey={1}
                as={Link}
                to="/main"
                className={`text-nav${
                  path.pathname === "/main" ? " active" : ""
                }`}
              >
                Jadwal Operasional
              </NavLink>
              <NavLink
                eventKey={2}
                as={Link}
                to="/fix-schedule"
                className={`text-nav${
                  path.pathname === "/fix-schedule" ? " active" : ""
                }`}
              >
                Jadwal Tetap
              </NavLink>
            </Nav>
            <Nav>
              <NavDropdown
                title={
                  <LoginImage
                    image={data.profileImageurl ?? "/dummy.jpg"}
                    name={data.name}
                  />
                }
                className="ps-4 ps-md-0"
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item href="#" onClick={handleCheckout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  return null;
};

export default NavbarComponent;
