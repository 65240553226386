import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import idLocale from "@fullcalendar/core/locales/id";
import React, { useState } from "react";
import moment from "moment";
import "moment/locale/id";
import "./style.css";
import { useOperationsContext } from "../../context/schedule-operational/operationsContext";
import FormJadwal from "../FormJadwal";
import callApi from "../../utils/fetch";
import { useLocalStorage } from "../../hook/storage";
import PopupInfo from "../PopupInfo";
import { toastErrorMessage, toastSuccessMessage } from "../ToastMessage";
import FormAddSchedule from "../AddSchedule/FormAddSchedule";

function renderEventContent(eventInfo) {
  const data = eventInfo.event._def.extendedProps;
  // console.log(eventInfo)
  const bgCard = {
    available: "#11CBE1",
    booked: "#F5A623",
    sold: "#5FDB6B",
    closed: "#FF5D5D",
  };
  return (
    <div
      className="p-2 rounded d-flex flex-column justify-content-between overflow-hidden"
      style={{ background: `${data.status in bgCard && bgCard[data.status]}` }}
    >
      <div>
        <p className="title-cal mb-0">{data.name}</p>
        <p className="text-regular mb-0">
          {moment(eventInfo.event.startStr).format("LT")} -{" "}
          {moment(eventInfo.event.endStr).format("LT")} WIB
        </p>
        <p className="text-regular">{eventInfo.event.title} menit</p>
      </div>
      {data.status === "sold" ||
        (data.status === "closed" && (
          <div className="d-flex align-items-center">
            <img
              className="rounded-circle me-2"
              src={eventInfo.backgroundColor}
              width={24}
              height={24}
              alt=""
            />
            <p className="title-cal mb-0">{eventInfo.textColor}</p>
          </div>
        ))}
    </div>
  );
}

const Calendar = () => {
  const [state, dispatch, getData] = useOperationsContext();
  const [token] = useLocalStorage("auth");
  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const [onLoadDate, setOnLoadDate] = useState(null);
  const [dateStart, setDateStart] = useState(new Date());
  const [selected, setSelected] = useState("");
  const [userName, setUserName] = useState("-");
  const [userImage, setUserImage] = useState("/dummy.jpg");

  const handleCloseForm = () => setShow(false);
  const handleCloseInfo = () => setShowInfo(false);
  const handleCloseAddSchedule = () => setShowAddSchedule(false);

  const onDateChange = (event) => {
    setDateStart(event);
    console.log(event)
  };

  const handleDelete = async () => {
    dispatch({
      type: "SCHEDULE_OPERATIONAL_REQUEST",
    });
    try {
      await callApi({
        method: "DELETE",
        url: `/schedule/operations/${
          state.detailOperations.psychologOpsId
        }/hour/${state.detailOperations.id}?isRecurring=${Number(selected)}`,
        token: token,
      });
      getData();
      toastSuccessMessage("Berhasil hapus jadwal");
      dispatch({
        type: "SCHEDULE_OPERATIONAL_SUCCESS",
      });
    } catch (err) {
      dispatch({
        type: "SCHEDULE_OPERATIONAL_ERROR",
      });
    } finally {
      handleCloseInfo();
    }
  };

  const onHandleUpdate = async () => {
    console.log('month updatte')
    dispatch({
      type: "SCHEDULE_OPERATIONAL_REQUEST",
    });
    try {
      const data = {
        date: moment(dateStart).format("YYYY-MM-DD"),
        hour: moment(dateStart).format("HH:mm:ss"),
        isRecurring: Number(selected),
      };
      await callApi({
        method: "PUT",
        url: `/schedule/operations/${state.detailOperations.psychologOpsId}/hour/${state.detailOperations.id}`,
        token: token,
        data: data,
      });
      getData();
      toastSuccessMessage("Berhasil ubah jadwal");
      dispatch({
        type: "SCHEDULE_OPERATIONAL_SUCCESS",
      });
    } catch (err) {
      dispatch({
        type: "SCHEDULE_OPERATIONAL_ERROR",
      });
    } finally {
      handleCloseForm();
    }
  };

  const onHandleChange = (e) => {
    setSelected(e.target.value);
    console.log(e)
  };

  return (
    <>
      <div className="container px-0 px-md-5 mb-5">
      
        {state.loading ? (
          <div className="text-center my-5">
            <div className="spinner-border text-info" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <FullCalendar
            locale={idLocale}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: "prev,next",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            events={state.dataOperations}
            eventContent={renderEventContent}
            dayMaxEvents={3}
            eventClick={async (info) => {
              const id = info.event.groupId;
              const hourId = info.event.id;
              setShow(true);
              if (state.isAvailable) {
                dispatch({
                  type: "SCHEDULE_DETAIL_OPERATIONAL_REQUEST",
                });
                await callApi({
                  url: `/schedule/operations/${id}/hour/${hourId}`,
                  method: "GET",
                  token: token,
                })
                  .then((res) => {
                    setUserName(
                      res.sessionPlan !== null
                        ? res.sessionPlan.session.user.name
                        : "-"
                    );
                    setUserImage(
                      res.sessionPlan !== null
                        ? res.sessionPlan.session.user.profileImageurl !== null
                          ? res.sessionPlan.session.user.profileImageurl
                          : "/dummy.jpg"
                        : "/dummy.jpg"
                    );

                    setDateStart(info.event.start);
                    setSelected(res.isRecurring === true ? "1" : "0");
                    dispatch({
                      type: "SCHEDULE_DETAIL_OPERATIONAL_SUCCESS",
                      payload: res,
                    });
                  })
                  .catch((e) => {
                    dispatch({
                      type: "SCHEDULE_DETAIL_OPERATIONAL_ERROR",
                    });
                  });
              } else {
                toastErrorMessage(
                  "Saat ini Anda sedang tidak aktif untuk menerima klien baru."
                );
              }
            }}
            dateClick={(dateClickInfo) => {
              if (state.isAvailable) {
                setShowAddSchedule(true);
                setOnLoadDate(dateClickInfo.date);
              } else {
                toastErrorMessage(
                  "Saat ini Anda sedang tidak aktif untuk menerima klien baru."
                );
              }
            }}
          />
        )}
      </div>

      <FormAddSchedule
        show={showAddSchedule}
        handleClose={handleCloseAddSchedule}
        dateStart={onLoadDate}
        handleDateChange={(e) => setOnLoadDate(e)}
      />
      <FormJadwal
        show={show}
        handleClose={handleCloseForm}
        title="Detail Jadwal"
        isLoading={state?.loadingDetail}
        detailPsycholog
        startDate={dateStart}
        dateChange={onDateChange}
        status={state?.detailOperations?.status || ""}
        statusTitle={state?.detailOperations?.statusLocalized || ""}
        icStatus={state?.detailOperations?.cluster?.type || ""}
        titleSchedule={state?.detailOperations?.cluster?.name || ""}
        durationSchedule={state?.detailOperations?.cluster?.duration || 0}
        handleDelete={() => {
          setShowInfo(true);
          handleCloseForm();
        }}
        viaName={state?.detailOperations?.cluster?.formattedType || ""}
        isChecked={selected}
        handleRadioChange={(e) => onHandleChange(e)}
        handleSubmit={onHandleUpdate}
        userName={userName}
        userImage={userImage}
      />
      <PopupInfo
        show={showInfo}
        handleClose={handleCloseInfo}
        handleClick={handleDelete}
        title="Hapus Jadwal Piket"
        description={
          selected === "1"
            ? "Apakah Anda yakin ingin menghapus jadwal ini secara berulang?"
            : "Apakah kamu yakin menghapus data ini?"
        }
        textLeft="Tidak"
        textRight="Ya"
      />
    </>
  );
};

export default Calendar;
