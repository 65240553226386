import React from "react";
import Button from "react-bootstrap/Button";
import { useOperationsContext } from "../../context/schedule-operational/operationsContext";

const CustomButton = ({ className, onClick, text, disabled }) => {
  const [state] = useOperationsContext();

  return (
    <Button className={className} onClick={onClick} disabled={disabled}>
      {(state.loadingSwitch || state.loading) ? (
        <div className="text-center">
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        text
      )}
    </Button>
  );
};

export default CustomButton;
