import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginForm from "../../components/LoginForm";
import { config } from "../../config";
import { useLoginContext } from "../../context/login/loginContext";
import { useLocalStorage } from "../../hook/storage";
import "./login.css";

const Login = () => {
  const navigate = useNavigate();
  const [, dispatch] = useLoginContext();
  const [token, setLogin] = useLocalStorage("auth");
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
 
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    dispatch({ type: "LOGIN_REQUEST" });
    try {
      const res = await axios.post(
        `${config.api_host}/auth/psycholog/login`,
        form
      );
      setLogin(res.data.token);
      dispatch({
        type: "LOGIN_SUCCESS",
      });
      window.location.replace("/main");
    } catch (error) {
      dispatch({ type: "LOGIN_ERROR" });
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  useEffect(() => {
    if (token) {
      return navigate("/main");
    } else {
      return navigate("/");
    }
  }, [token, navigate]);

  return (
    <div className="bg-login">
      <LoginForm
        form={form}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleKeyDown={handleKeyDown}
      />
      <div className="bg-white fixed-bottom text-center py-3">
        <p className="text-bigger text-medium text-dark mb-0">
          PT Riliv Psikologi Indonesia
        </p>
      </div>
    </div>
  );
};

export default Login;
