import {
  SCHEDULE_TEMPLATE_REQUEST,
  SCHEDULE_TEMPLATE_SUCCESS,
  SCHEDULE_TEMPLATE_ERROR,
} from "./constant";

export function reducer(state, action) {
  switch (action.type) {
    case SCHEDULE_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SCHEDULE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        dataTemplate: action.payload,
      };
    case SCHEDULE_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
