export const SCHEDULE_OPERATIONAL_REQUEST = "SCHEDULE_OPERATIONAL_REQUEST";
export const SCHEDULE_OPERATIONAL_SUCCESS = "SCHEDULE_OPERATIONAL_SUCCESS";
export const SCHEDULE_OPERATIONAL_ERROR = "SCHEDULE_OPERATIONAL_ERROR";
export const SCHEDULE_DETAIL_OPERATIONAL_REQUEST =
  "SCHEDULE_DETAIL_OPERATIONAL_REQUEST";
export const SCHEDULE_DETAIL_OPERATIONAL_SUCCESS =
  "SCHEDULE_DETAIL_OPERATIONAL_SUCCESS";
export const SCHEDULE_DETAIL_OPERATIONAL_ERROR =
  "SCHEDULE_DETAIL_OPERATIONAL_ERROR";
  export const SCHEDULE_SWITCH_REQUEST = "SCHEDULE_SWITCH_REQUEST";
  export const SCHEDULE_SWITCH_SUCCESS = "SCHEDULE_SWITCH_SUCCESS";
  export const SCHEDULE_SWITCH_ERROR = "SCHEDULE_SWITCH_ERROR";