import axios from "axios";
import React, { useState } from "react";
import { config } from "../../config";
import { useOperationsContext } from "../../context/schedule-operational/operationsContext";
import { useLocalStorage } from "../../hook/storage";
import PopupInfo from "../PopupInfo";
import { toastErrorMessage } from "../ToastMessage";
import "./style.css";

const SwitchSchedule = () => {
  const [token] = useLocalStorage("auth");
  const [state, dispatch] = useOperationsContext();
  const [checkedVal, setCheckedVal] = useState(false);
  const handleClose = () => setCheckedVal(false);

  const onChangeAvailability = async () => {
    dispatch({
      type: "SCHEDULE_SWITCH_REQUEST",
    });

    try {
      await axios.post(
        `${config.api_host}/schedule/operations/availability`,
        { available: !state.isAvailable },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // toast.success(res.data.message, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   theme: "colored",
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      handleClose();
      // dispatch({
      //   type: "SCHEDULE_SWITCH_SUCCESS",
      //   payload: !state.isAvailable,
      // });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: "SCHEDULE_SWITCH_ERROR",
      });
      toastErrorMessage(err.response.data.message || "Terjadi kesalahan");
    }
  };

  return (
    <>
      <div className="container bg-white py-4 px-3 px-md-5">
        <div className="bg-blue p-3 p-md-4 mb-4 rounded d-block d-md-flex justify-content-between align-items-center">
          {state.loadingSwitch ? (
            <div className="mx-auto">
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <div className="me-3 w-100 w-md-75 mb-2 mb-md-0">
                <span className="text-white text-medium subtitle">
                  {state.isAvailable
                    ? "Saat ini Anda sedang aktif untuk menerima klien baru dan melakukan sesi konseling yang sudah terjadwal"
                    : "Saat ini Anda sedang tidak aktif untuk menerima klien baru. Namun, untuk sesi konseling yang sudah terjadwal dengan klien akan tetap dilaksanakan"}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <span className="text-medium text-white me-3 switch-text d-block">
                  {state.isAvailable ? "ON" : "OFF"}
                </span>
                <input
                  type="checkbox"
                  id="switch"
                  onChange={() =>
                    state.isAvailable
                      ? setCheckedVal(true)
                      : onChangeAvailability()
                  }
                  checked={state.isAvailable}
                />
                <label htmlFor="switch" className="switch">
                  Toggle
                </label>
              </div>
            </>
          )}
        </div>
      </div>
      <PopupInfo
        show={checkedVal}
        handleClose={handleClose}
        handleClick={onChangeAvailability}
        title="Yakin ingin menonaktifkan jadwal mulai hari ini?"
        description="Jika Anda sedang off, jadwal yang tersedia tidak dapat dipilih oleh klien. Namun, jadwal yang telah terjual akan tetap dilakukan sesi konselingnya"
        textLeft="Kembali"
        textRight="Lanjutkan"
      />
    </>
  );
};

export default SwitchSchedule;
