import React, { useCallback, useEffect } from "react";
import AddSchedule from "../../components/AddSchedule";
import Content from "../../components/FixScheduleContent";
import Footer from "../../components/Footer";
import { useTemplateContext } from "../../context/schedule-template/templateContext";
import { useLocalStorage } from "../../hook/storage";
import callApi from "../../utils/fetch";

const FixSchedule = () => {
  const [token] = useLocalStorage("auth");
  const [state, dispatch] = useTemplateContext();

  const getData = useCallback(async () => {
    dispatch({
      type: "SCHEDULE_TEMPLATE_REQUEST",
    });
    try {
      const res = await callApi({
        url: "/schedule/templates",
        token: token,
        method: "GET",
      });
      dispatch({
        type: "SCHEDULE_TEMPLATE_SUCCESS",
        payload: res,
      });
    } catch (err) {}
  }, [token, dispatch]);

  useEffect(() => {
    getData();
  }, [token, getData]);

  return (
    <>
      <AddSchedule subtitle="Lihat jadwal tetap konseling Anda yang telah diinput di awal" />
      {state.loading ? (
        <div className="text-center my-5">
          <div className="spinner-border text-info" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <Content />
      )}
      <Footer />
    </>
  );
};

export default FixSchedule;
