import { createContext, useContext, useReducer } from "react";
import { reducer } from "./reducer";

export const TemplateContext = createContext({});

export function useTemplateContext() {
  return useContext(TemplateContext);
}

const initialState = {
  loading: false,
  dataTemplate: [],
};

export function ScheduleTemplateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TemplateContext.Provider value={[state, dispatch]}>
      {children}
    </TemplateContext.Provider>
  );
}
