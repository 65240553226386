import moment from "moment";
import React from "react";
import { useTemplateContext } from "../../context/schedule-template/templateContext";
moment.locale("id");

const Content = () => {
  const [state] = useTemplateContext();
  
  const sortDay = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 7,
  };

  const days = {
    mon: "Senin",
    tue: "Selasa",
    wed: "Rabu",
    thu: "Kamis",
    fri: "Jumat",
    sat: "Sabtu",
    sun: "Minggu",
  };

  const types = {
    Chat: "/chat.png",
    Call: "/call.png",
    "Video Call": "/vidcall.png",
  };

  return (
    <div className="container px-3 px-md-5 my-4 my-md-5">
      <div className="border rounded px-4 py-3">
        <div className="d-none d-md-block">
          <table className="table">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="text text-medium"
                  style={{ color: "#B5B5C3" }}
                >
                  HARI
                </th>
                <th
                  scope="col"
                  className="text text-medium"
                  style={{ color: "#B5B5C3" }}
                >
                  LAYANAN
                </th>
                <th
                  scope="col"
                  className="text text-medium"
                  style={{ color: "#B5B5C3" }}
                >
                  DURASI
                </th>
                <th
                  scope="col"
                  className="text text-medium"
                  style={{ color: "#B5B5C3" }}
                >
                  JUMLAH SLOT
                </th>
                <th
                  scope="col"
                  className="text text-medium"
                  style={{ color: "#B5B5C3" }}
                >
                  JAM SESI
                </th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: "middle" }}>
              {state.dataTemplate
                .sort((a, b) => sortDay[a.day] - sortDay[b.day])
                .map((item) => (
                  <tr key={item.id}>
                    <td className="text text-dark">
                      {item.day in days && days[item.day]}
                    </td>
                    <td className="text text-dark">
                      <ul style={{ paddingLeft: "0", marginBottom: "0" }}>
                        {item.hours.map((res, index) => (
                          <li
                            key={index}
                            className="d-flex align-items-center mb-2"
                          >
                            <img
                              style={{ width: "24px", height: "24px" }}
                              className="me-2"
                              src={res.type in types && types[res.type]}
                              alt="icon"
                            />
                            {res.type}
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td className="text text-dark">
                      <ul style={{ paddingLeft: "0", marginBottom: "0" }}>
                        {item.hours.map((res, index) => (
                          <li
                            key={index}
                            className="mb-2"
                            style={{ height: "24px" }}
                          >
                            {res.duration} menit
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td className="text text-dark">
                      <ul style={{ paddingLeft: "0", marginBottom: "0" }}>
                        {item.hours.map((res, index) => (
                          <li
                            key={index}
                            className="mb-2"
                            style={{ height: "24px" }}
                          >
                            Melayani {res.slot} slot
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td className="text text-dark">
                      <ul style={{ paddingLeft: "0", marginBottom: "0" }}>
                        {item.hours.map((res, index) => (
                          <li
                            key={index}
                            className="mb-2"
                            style={{ height: "24px" }}
                          >
                            {res.start} - {res.end} WIB
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="d-block d-md-none">
          {state.dataTemplate
            .sort((a, b) => sortDay[a.day] - sortDay[b.day])
            .map((item) => (
              <div key={item.id} className="border-bottom mb-2">
                <h5 className="text-bigger text-medium">
                  {item.day in days && days[item.day]}
                </h5>
                {item.hours.map((res, index) => (
                  <div key={index}>
                    <div className="d-flex align-items-center text-bigger">
                      <img
                        style={{ width: "24px", height: "24px" }}
                        className="me-2"
                        src={res.type in types && types[res.type]}
                        alt="icon"
                      />
                      {res.type}
                    </div>
                    <ul className="text-bigger">
                      <li>{res.duration} menit</li>
                      <li>Melayani {res.slot} slot</li>
                      <li>
                        {res.start} - {res.end} WIB
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Content;
