import { LOGIN_ERROR, LOGIN_REQUEST, LOGIN_SUCCESS } from "./constant";

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLogin: true
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        isLogin: false
      };

    default:
      return state;
  }
}
