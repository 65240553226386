import React from "react";
import Modal from "react-bootstrap/Modal";
import CustomButton from "../Button";

const PopupInfo = ({
  show,
  handleClose,
  title,
  description,
  textLeft,
  textRight,
  handleClick,
}) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <h2 className="text-medium text-title">{title}</h2>
      </Modal.Header>
      <Modal.Body>
        <p className="text-bigger">{description}</p>
        <div className="d-flex justify-content-between mt-4">
          <CustomButton
            className="w-100 btn-border text-medium text-orange me-2"
            text={textLeft}
            onClick={handleClose}
          />
          <CustomButton
            className="w-100 btn-custom text-medium ms-2"
            text={textRight}
            onClick={handleClick}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PopupInfo;
