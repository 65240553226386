import axios from "axios";
import { config } from "../config";

export default async function callApi({ url, method, data, token }) {
  let headers = {
    Authorization: token
  }
  let mainUrl = `${config.api_host}${url}`
  const response = await axios({
    url: mainUrl,
    method,
    data,
    headers: headers,
  });
  const axiosResponse = response.data;
  return axiosResponse.data;
}
