import React from "react";
import id from "date-fns/locale/id";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CustomButton from "../Button";
import "./style.css";

const FormJadwal = ({
  handleClose,
  show,
  title,
  isLoading,
  detailPsycholog,
  handleDelete,
  handleSubmit,
  startDate,
  dateChange,
  icStatus,
  titleSchedule,
  durationSchedule,
  status,
  statusTitle,
  isAddData,
  defaultValueService,
  nameService,
  optionsService,
  onChangeService,
  nameSlot,
  onChangeSlot,
  typeChange,
  textSlot,
  valueNoText,
  isChecked,
  handleRadioChange,
  viaName,
  userImage,
  userName,
}) => {
  let endDate = moment(startDate).add(durationSchedule, "m").format("HH:mm");

  const timeNow = moment(new Date()).format();
  const endTime = moment(startDate).subtract(60, "m").format();

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <h2 className="text-medium text-title mb-0">{title}</h2>
      </Modal.Header>
      {isLoading ? (
        <div className="text-center my-5">
          <div className="spinner-border text-info" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <Modal.Body>
          {!detailPsycholog && (
            <div className="mb-3">
              <Row>
                <Col className="me-1">
                  <h4 className="text-section text-dark text-medium">
                    Layanan
                  </h4>
                  <Select
                    className="text text-dark"
                    classNamePrefix="select"
                    defaultValue={defaultValueService}
                    isLoading={isLoading}
                    isClearable
                    isSearchable
                    name={nameService}
                    options={optionsService}
                    onChange={onChangeService}
                  />
                </Col>
                <Col className="ms-1">
                  <h4 className="text-section text-dark text-medium">
                    Jumlah Slot
                  </h4>
                  {typeChange === "text" ? (
                    <Select
                      className="text text-dark"
                      classNamePrefix="select"
                      isLoading={isLoading}
                      isClearable
                      name={nameSlot}
                      options={textSlot}
                      onChange={onChangeSlot}
                      placeholder="Pilih Slot"
                    />
                  ) : (
                    <Select
                      className="text text-dark"
                      classNamePrefix="select"
                      isLoading={isLoading}
                      name={nameSlot}
                      options={valueNoText}
                      value={valueNoText}
                      placeholder="Pilih Slot"
                      isDisabled
                    />
                  )}
                </Col>
              </Row>
            </div>
          )}
          {detailPsycholog && (
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex align-items-center">
                <img
                  src={
                    status === "sold" || status === "closed"
                      ? userImage
                      : icStatus === "text"
                      ? "/chat.png"
                      : icStatus === "call"
                      ? "/call.png"
                      : "/vidcall.png"
                  }
                  alt="icon"
                  className="img-icon rounded-circle"
                />
                <div className="d-block ms-2">
                  <h5 className="text-bigger text-dark text-medium mb-0">
                    {status === "sold" ? userName : titleSchedule}
                  </h5>
                  <div className="text text-soft d-block d-md-flex">
                    {status === "sold" && (
                      <div>
                        <img
                          src={
                            icStatus === "text"
                              ? "/ic-chat.png"
                              : icStatus === "call"
                              ? "/ic-call.png"
                              : "/ic-video.png"
                          }
                          alt="icon"
                          style={{ width: "24px" }}
                        />{" "}
                        <span className="text-blue">via {viaName}</span>&nbsp;
                      </div>
                    )}
                    <span>{durationSchedule} menit</span>
                  </div>
                </div>
              </div>
              <span
                className="badge rounded-pill py-2 px-3"
                style={{
                  background:
                    status === "available"
                      ? "#11CBE1"
                      : status === "booked"
                      ? "#F5A623"
                      : status === "sold"
                      ? "#5FDB6B"
                      : "#FF5D5D",
                }}
              >
                Jadwal {statusTitle}
              </span>
            </div>
          )}
          <div className="mb-3">
            <Row>
              <Col sm className="me-0 me-md-1 mb-3 mb-md-0">
                <h4 className="text-section text-dark text-medium">
                  Mulai Jam
                </h4>
                <DatePicker
                  locale={id}
                  selected={startDate}
                  onChange={(date) => dateChange(date)}
                  dateFormat="dd MMMM yyyy, HH:mm"
                  className="form-control text-dark text"
                  showTimeSelect
                  minDate={new Date()}
                  timeIntervals={15}
                  placeholderText="Jadwal Mulai"
                  disabled={
                    status === "sold" ||
                    status === "booked" ||
                    status === "closed" ||
                    (status === "available" && timeNow >= endTime)
                  }
                />
              </Col>
              <Col sm className="ms-0 ms-md-1">
                <h4 className="text-section text-dark text-medium">
                  Sampai Jam
                </h4>
                <input
                  type="text"
                  className="form-control text-dark text"
                  value={endDate === "Invalid date" ? "--:--" : endDate}
                  readOnly
                  disabled
                />
              </Col>
            </Row>
            <span className="text text-grey">
              Waktu Indonesia Barat (GMT +07:00)
            </span>
            {detailPsycholog && status === "available" && timeNow >= endTime && (
              <div className="d-flex align-items-center bg-soft-blue p-2 rounded mt-3">
                <img
                  className="me-2"
                  src="/ic-info.png"
                  alt="ic-info"
                  style={{ width: "20px", height: "20px" }}
                />
                <span className="text text-dark">
                  Maaf, Anda tidak bisa mengganti jadwal 1 jam sebelum sesi
                  dimulai, hubungi admin jika Anda membutuhkan bantuan lebih
                  lanjut
                </span>
              </div>
            )}
          </div>
          {(isAddData || (status === "available" && timeNow <= endTime)) && (
            <div>
              <h4 className="text-section text-dark text-medium">
                Jadwal Berulang
              </h4>

              <Form.Check
                inline
                label="Hanya jadwal ini"
                name="group1"
                type="radio"
                id="jadwal-1"
                checked={isChecked === "0"}
                value="0"
                onChange={handleRadioChange}
              />
              <Form.Check
                inline
                label="Jadwal ini & seterusnya"
                name="group1"
                type="radio"
                id="jadwal-2"
                checked={isChecked === "1"}
                value="1"
                onChange={handleRadioChange}
              />
            </div>
          )}
          {status === "available" && timeNow >= endTime ? null : (
            <div className="d-flex justify-content-between">
              {detailPsycholog && status === "available" && (
                <CustomButton
                  className="btn-danger w-100 mt-4 text-medium me-2"
                  text="Hapus"
                  onClick={handleDelete}
                />
              )}
              {(isAddData || status === "available") && (
                <CustomButton
                  className="btn-custom w-100 mt-4 text-medium"
                  text="Simpan"
                  onClick={handleSubmit}
                />
              )}
            </div>
          )}
        </Modal.Body>
      )}
    </Modal>
  );
};

export default FormJadwal;
