import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/Navbar";
import { LoginProvider } from "./context/login/loginContext";
import { ScheduleOperationProvider } from "./context/schedule-operational/operationsContext";
import { ScheduleTemplateProvider } from "./context/schedule-template/templateContext";
import Login from "./pages/Login";
import Main from "./pages/Main";
import FixSchedule from "./pages/Main/fix-schedule";

function App() {
  
  return (
    <Router>
      <LoginProvider>
        <ScheduleOperationProvider>
          <ScheduleTemplateProvider>
            <Navbar />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/main" element={<Main />} />
              <Route path="/fix-schedule" element={<FixSchedule />} />
            </Routes>
            <ToastContainer />
          </ScheduleTemplateProvider>
        </ScheduleOperationProvider>
      </LoginProvider>
    </Router>
  );
}

export default App;
