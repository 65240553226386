import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./login-form.css";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useState } from "react";
import { useLoginContext } from "../../context/login/loginContext";

const LoginForm = ({ form, handleChange, handleSubmit, handleKeyDown }) => {
  const [show, setShow] = useState(false);
  const [state] = useLoginContext();

  return (
    <div className="container bg-white rounded-4 box-login">
      <figure className="text-center mb-5">
        <img src="/riliv-logo.png" alt="logo" />
      </figure>
      <div className="mx-auto">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="text-medium">Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Masukkan Email"
            className="text"
            name="email"
            value={form.email}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            required
          />
        </Form.Group>

        <Form.Group className="mb-5" controlId="formBasicPassword">
          <Form.Label className="text-medium">Password</Form.Label>
          <div className="position-relative">
            <Form.Control
              type={show ? "text" : "password"}
              placeholder="Masukkan Password"
              className="text"
              name="password"
              value={form.password}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              required
            />
            <button
              onClick={() => setShow(!show)}
              className="bg-transparent border-0 position-absolute"
              style={{
                top: "4px",
                right: "10px",
              }}
            >
              {show ? (
                <FaEyeSlash className="text-grey" />
                ) : (
                <FaEye className="text-grey" />
              )}
            </button>
          </div>
        </Form.Group>
        <Button
          className="btn-custom w-100 text-medium"
          type="button"
          onClick={handleSubmit}
          disabled={state.isLoading}
        >
          {state.isLoading ? (
            <div>
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </div>
          ) : (
            "Login"
          )}
        </Button>
      </div>
    </div>
  );
};

export default LoginForm;
