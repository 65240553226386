import React, { useState } from "react";
import CustomButton from "../Button";
import "moment/locale/id";
import { useOperationsContext } from "../../context/schedule-operational/operationsContext";
import FormAddSchedule from "./FormAddSchedule";

const AddSchedule = ({ isShowSwitch, subtitle }) => {
  const [state] = useOperationsContext();
  const [startDate, setStartDate] = useState(null);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="bg-grey py-4">
        <div className="container px-4 px-md-5 d-block d-md-flex justify-content-between align-items-center">
          <div>
            <h1 className="title">Jadwal Praktik Konseling</h1>
            <p className="text-grey subtitle">{subtitle}</p>
          </div>
          {isShowSwitch && (
            <CustomButton
              text="Tambah Jadwal"
              className="btn-custom text-medium"
              onClick={handleShow}
              disabled={!state.isAvailable}
            />
          )}
        </div>
      </div>
      <FormAddSchedule
        show={show}
        handleClose={handleClose}
        dateStart={startDate}
        handleDateChange={(e) => setStartDate(e)}
      />
    </>
  );
};

export default AddSchedule;
