import moment from "moment";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useLocalStorage } from "../../hook/storage";
import callApi from "../../utils/fetch";
import { reducer } from "./reducer";
moment.locale("id");

export const OperationsContext = createContext({});

export function useOperationsContext() {
  return useContext(OperationsContext);
}

const initialState = {
  loading: false,
  loadingSwitch: false,
  loadingDetail: false,
  dataOperations: [],
  detailOperations: {},
  isAvailable: false,
};

export function ScheduleOperationProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [token] = useLocalStorage("auth");

  const getData = useCallback(async () => {
    dispatch({
      type: "SCHEDULE_OPERATIONAL_REQUEST",
    });
    try {
      let _temp = [];
      const res = await callApi({
        url: "/schedule/operations?view=monthly",
        token: token,
        method: "GET",
      });

      dispatch({
        type: "SCHEDULE_SWITCH_SUCCESS",
        payload: res[0].isAvailable,
      });

      for (const result of res) {
        const hours = result.hours;
        for (const item of hours) {
          const checkUser = item.sessionPlan !== null ? item.sessionPlan : null;
          const checkNameImage =
            checkUser !== null ? checkUser.session.user : null;
          const userName = checkNameImage !== null ? checkNameImage.name : "-";

          const imgUser =
            checkNameImage !== null
              ? checkNameImage.profileImageurl !== null
                ? checkNameImage.profileImageurl
                : "/dummy.jpg"
              : "/dummy.jpg";

          const hour = item.hour.split(":");

          const startDate = moment(result.date)
            .add({
              hours: hour[0],
              minutes: hour[1],
              seconds: hour[2],
            })
            .format();

          _temp.push({
            start: startDate,
            end: moment(startDate, "YYYY-MM-DD HH:mm")
              .add(item.cluster.duration, "minutes")
              .format(),
            status: item.status,
            name: item.cluster.name,
            title: item.cluster.duration.toString(),
            hour: item.hour,
            groupId: result.id,
            id: item.id,
            textColor: userName,
            backgroundColor: imgUser,
          });
        }

        dispatch({
          type: "SCHEDULE_OPERATIONAL_SUCCESS",
          payload: _temp,
        });
      }
    } catch (err) {
      dispatch({
        type: "SCHEDULE_OPERATIONAL_ERROR",
      });
    }
  }, [token, dispatch]);

  useEffect(() => {
    getData();
  }, [token, getData]);

  return (
    <OperationsContext.Provider value={[state, dispatch, getData]}>
      {children}
    </OperationsContext.Provider>
  );
}
