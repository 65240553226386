import React, { useState, useEffect, useCallback } from "react";
import { useLocalStorage } from "../../hook/storage";
import callApi from "../../utils/fetch";
import FormJadwal from "../FormJadwal";
import moment from "moment";
import "moment/locale/id";
import { useOperationsContext } from "../../context/schedule-operational/operationsContext";
import { toastErrorMessage, toastSuccessMessage } from "../ToastMessage";

const FormAddSchedule = ({
  show,
  handleClose,
  dateStart,
  handleDateChange,
}) => {
  const [token] = useLocalStorage("auth");
  const [, dispatch, getData] = useOperationsContext();
  const [dataService, setDataService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [duration, setDuration] = useState(0);
  const [clusterId, setClusterId] = useState(null);
  const [slot, setSlot] = useState(1);
  const [selected, setSelected] = useState("");

  const textSlot = [
    { label: "1 Slot", value: 1 },
    { label: "2 Slot", value: 2 },
    { label: "3 Slot", value: 3 },
  ];

  const noTextSlot = [{ label: "1 Slot", value: 1 }];

  const getDataService = useCallback(async () => {
    setLoading(true);
    try {
      const res = await callApi({
        url: "/clusters",
        method: "GET",
        token: token,
      });

      let _temp = [];
      res.forEach((ele) => {
        _temp.push({
          label: ele.name,
          value: ele.id,
          type: ele.type,
          duration: ele.duration,
        });
      });
      setDataService(_temp);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [token]);

  const onChangeService = (e) => {
    setType(e.type);
    setDuration(e.duration);
    setClusterId(e.value);
  };

  // const onDateChange = (e) => {
  //   console.log(e);
  //   setStartDate(e);
  // };

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const onChangeSlot = (e) => {
    setSlot(e.value);
  };

  const onSubmit = async () => {
    dispatch({
      type: "SCHEDULE_OPERATIONAL_REQUEST",
    });
    try {
      let data = {
        clusterId: clusterId,
        date: moment(dateStart).format("YYYY-MM-DD"),
        hour: moment(dateStart).format("HH:mm:ss"),
        slot: slot,
        isRecurring: Number(selected),
      };

      if (data.clusterId === null) {
        toastErrorMessage("Layanan harus diisi!");
        dispatch({
          type: "SCHEDULE_OPERATIONAL_ERROR",
        });
      } else if (data.date === "Invalid date") {
        toastErrorMessage("Tanggal dan Jam harus diisi!");
        dispatch({
          type: "SCHEDULE_OPERATIONAL_ERROR",
        });
      } else if (selected === "") {
        toastErrorMessage("Jadwal Berulang harus diisi!");
        dispatch({
          type: "SCHEDULE_OPERATIONAL_ERROR",
        });
      } else {
        await callApi({
          method: "POST",
          url: "/schedule/operations",
          token: token,
          data: data,
        });

        getData();
        handleDateChange(null);
        handleClose();
        setSelected("");
        setSlot(1);
        toastSuccessMessage("Berhasil tambah jadwal");
        dispatch({
          type: "SCHEDULE_OPERATIONAL_SUCCESS",
        });
      }
    } catch (err) {
      dispatch({
        type: "SCHEDULE_OPERATIONAL_ERROR",
      });
      handleClose();
      toastErrorMessage(err.response.data.data[0]);
    }
  };

  useEffect(() => {
    getDataService();
  }, [token, getDataService]);

  return (
    <FormJadwal
      show={show}
      handleClose={handleClose}
      isAddData
      title="Tambah Jadwal"
      optionsService={dataService}
      isLoading={loading}
      onChangeService={(e) => onChangeService(e)}
      typeChange={type}
      textSlot={textSlot}
      valueNoText={noTextSlot}
      onChangeSlot={onChangeSlot}
      startDate={dateStart}
      dateChange={handleDateChange}
      durationSchedule={duration}
      isChecked={selected}
      handleRadioChange={handleChange}
      handleSubmit={onSubmit}
    />
  );
};

export default FormAddSchedule;
